import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar } from 'antd'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  const [inactiveTime, setInactiveTime] = useState(0)

  useEffect(() => {
    let timer

    const resetTimer = () => {
      clearTimeout(timer)
      setInactiveTime(0)
      startTimer()
    }

    const startTimer = () => {
      timer = setTimeout(() => {
        // Run your inactivity function here

        logout()
      }, 1000 * 60 * 20)
    }

    const handleActivity = () => {
      resetTimer()
      console.log('User active')
    }

    // Set up event listeners for activity
    document.addEventListener('mousemove', handleActivity)
    document.addEventListener('keydown', handleActivity)

    // Start the initial timer
    startTimer()

    // Clean up event listeners and timers when the component unmounts
    return () => {
      document.removeEventListener('mousemove', handleActivity)
      document.removeEventListener('keydown', handleActivity)
      clearTimeout(timer)
    }
  }, [inactiveTime])

  const logout = e => {
    if (e !== undefined) {
      e.preventDefault()
    }
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />, {user.name || 'Anonymous'}
        </strong>

        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.role" />:{' '}
          </strong>
          {user.roleDesc || '—'}
        </div>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu}>
      <Avatar className={styles.avatar} shape="square" size="medium" icon={<UserOutlined />} />
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
