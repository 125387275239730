import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import Layout from 'layouts'

const routes = [
  {
    path: '/labelprinting',
    Component: lazy(() => import('pages/LabelPrinting')),
    exact: true,
  },
  {
    path: '/report/ssmreportv',
    Component: lazy(() => import('pages/reports/SSMVendor')),
    exact: true,
  },
  // Asn process
  {
    path: '/asn/asnrequestvendor',
    Component: lazy(() => import('pages/ASN/AsnRequestVendorScreen')),
    exact: true,
  },
  {
    path: '/dashboard/deptlanding',
    Component: lazy(() => import('pages/dashboard/dlandingpage')),
    exact: true,
  },
  {
    path: '/dashboard/vedorlanding',
    Component: lazy(() => import('pages/dashboard/vlandingpage')),
    exact: true,
  },

  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  // Delivery Schedule
  {
    path: '/delivery/deliverySchedule',
    Component: lazy(() => import('pages/delivery/deliverySchedule')),
    exact: true,
  },
  {
    path: '/delivery/podeliverySchedule',
    Component: lazy(() => import('pages/delivery/poDeliverySchedulePlan')),
    exact: true,
  },
  // PO Confimrmation
  {
    path: '/po/ponfirmation',
    Component: lazy(() => import('pages/poConfirmation')),
    exact: true,
  },
  {
    path: '/po/ponfirmationstatus',
    Component: lazy(() => import('pages/poConfirmationStatus')),
    exact: true,
  },

  // PO delivery status
  {
    path: '/po/podeliverystatus',
    Component: lazy(() => import('components/poDeliveryStatus')),
    exact: true,
  },
  // Reports
  {
    path: '/report/ssmreport',
    Component: lazy(() => import('pages/reports/ssm_report')),
    exact: true,
  },
  {
    path: '/report/transitReport',
    Component: lazy(() => import('pages/reports/transitReport')),
    exact: true,
  },
  {
    path: '/report/asnReport',
    Component: lazy(() => import('pages/reports/asnReport')),
    exact: true,
  },
  {
    path: '/report/stockStatusReport',
    Component: lazy(() => import('pages/reports/stockStatusReport')),
    exact: true,
  },
  {
    path: '/report/stockStatusReportWithoutValues',
    Component: lazy(() => import('pages/reports/stockStatusReportWithoutValues')),
    exact: true,
  },
  {
    path: '/report/inspectionReport',
    Component: lazy(() => import('pages/reports/inspectionReport')),
    exact: true,
  },
  {
    path: '/report/invoiceandPackingListReport',
    Component: lazy(() => import('pages/reports/invoiceAndPackingList')),
    exact: true,
  },
  {
    path: '/report/AWBBLReport',
    Component: lazy(() => import('pages/reports/AWBLReport')),
    exact: true,
  },
  {
    path: '/report/BOEReport',
    Component: lazy(() => import('pages/reports/BOEReport')),
    exact: true,
  },
  {
    path: '/report/goodsReceiptPO',
    Component: lazy(() => import('pages/reports/goodsReceiptPO')),
    exact: true,
  },
  {
    path: '/report/paymentDetails',
    Component: lazy(() => import('pages/reports/paymentDetails')),
    exact: true,
  },
  {
    path: '/report/forecastReport',
    Component: lazy(() => import('pages/reports/foreCastReport')),
    exact: true,
  },
  {
    path: '/asn/pendingpoasn',
    Component: lazy(() => import('pages/ASN/Pending-Po-ASN')),
    exact: true,
  },
  // view ASN
  {
    path: '/asn/asnlist',
    Component: lazy(() => import('pages/ASN/ASN-List')),
    exact: true,
  },
  {
    path: '/asn/asnapprovelist',
    Component: lazy(() => import('pages/ASN/ASNApproveListDept')),
    exact: true,
  },
  {
    path: '/asn/asnListForDepartment',
    Component: lazy(() => import('pages/ASN/ASNListForDepartment')),
    exact: true,
  },
  {
    path: '/asn/asnLabelPrint',
    Component: lazy(() => import('pages/ASN/ASNLabelPrint')),
    exact: true,
  },
  {
    path: '/packing/packingMaster',
    Component: lazy(() => import('pages/delivery/packingMaster')),
    exact: true,
  },
  // Document
  {
    path: '/Documents/InspectReportFile',
    Component: lazy(() => import('pages/Documents/InspectReportFile')),
    exact: true,
  },
  {
    path: '/Documents/InvoiceReportFIle',
    Component: lazy(() => import('pages/Documents/InvoiceReportFIle')),
    exact: true,
  },
  {
    path: '/Documents/BOEReport',
    Component: lazy(() => import('pages/Documents/BOEReport')),
    exact: true,
  },
  {
    path: '/Documents/AWB/BL',
    Component: lazy(() => import('pages/Documents/AWB/BL')),
    exact: true,
  },
  {
    path: '/Documents/AWB/Vendor',
    Component: lazy(() => import('pages/Documents/AWB/Vendor')),
    exact: true,
  },
  // Settings
  {
    path: '/settings/addprofile',
    Component: lazy(() => import('pages/settings/AddProfile')),
    exact: true,
  },
  {
    path: '/settings/profile',
    Component: lazy(() => import('pages/settings/profile')),
    exact: true,
  },
  {
    path: '/settings/changepassword',
    Component: lazy(() => import('pages/admin/changepassword')),
    exact: true,
  },
  {
    path: '/settings/foreCast',
    Component: lazy(() => import('pages/admin/foreCast')),
    exact: true,
  },
  // Supplier List
  {
    path: '/settings/supplierlist',
    Component: lazy(() => import('pages/supplierList')),
    exact: true,
  },
  {
    path: '/settings/PackingMaster',
    Component: lazy(() => import('pages/supplierMaster')),
    exact: true,
  },
  {
    path: '/delivery/ActivityLog',
    Component: lazy(() => import('pages/delivery/ActivityLog')),
    exact: true,
  },
  {
    path: '/asn/RMMaterialMaster',
    Component: lazy(() => import('pages/ASN/RMMaterialMaster')),
    exact: true,
  },
  {
    path: '/asn/RMMaterialTransaction',
    Component: lazy(() => import('pages/ASN/RMaterialTransaction')),
    exact: true,
  },
]

const mapStateToProps = ({ settings, user }) => ({
  routerAnimation: settings.routerAnimation,
  userType: user.userType,
})

const Router = ({ history, routerAnimation, userType }) => {
  console.log(`groupCode ${userType}`)
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {console.log(userType)}
                    <Route
                      exact
                      path="/"
                      render={() => {
                        return userType !== '' && userType !== null && userType === '1' ? (
                          <Redirect to="/dashboard/vedorlanding" />
                        ) : (
                          <Redirect to="/dashboard/deptlanding" />
                        )
                      }}
                    />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
