const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  SET_PLANT_CODE: 'user/SET_PLANTCODE',
  SET_PLANT_CODE_STATE: 'user/SET_PLANTCODE_STATE',
  SET_DEVICE_CODE_STATE: 'user/SET_DEVICE_CODE_STATE',
  LOGOUT: 'user/LOGOUT',
}

export default actions
