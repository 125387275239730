import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

const apiServer = axios.create({
  // baseURL: 'http://10.215.0.73:8988/magna/mysqlclient',
  // baseURL: 'http://localhost:8988/magna/mysqlclient',
     baseURL: 'http://che-magna-portal.magna.com:8988/magna/mysqlclient',
  // baseURL: 'http://10.11.100.24:8988/magna/mysqlclient',
  // baseURL: 'http://192.168.0.189:8988/magna/mysqlclient',
  // baseURL: 'http://192.168.1.126:8989',
  // timeout: 1000,
  headers: { 'Content-Type': 'application/json' },
})

apiServer.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  const accessIsRedirect = store.get('isRedirect')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
    request.headers.redirect_enabled = accessIsRedirect
  }

  return request
})

apiServer.interceptors.response.use(undefined, errorin => {
  // Errors handling
  console.log(`Damn error ${errorin}`)
  const { response } = errorin
  if (response !== null && response !== undefined) {
    const { error } = response.data
    if (error) {
      if (error !== 'Bad Request') {
        notification.warning({
          message: error,
        })
      }
    }
  }
})

export default apiServer
