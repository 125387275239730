import update from 'react-addons-update'
import actions from './actions'

const initialState = {
  id: '',
  name: '',
  role: '',
  email: '',
  avatar: '',
  plantcode: '',
  plantType: '',
  groupCode: '',
  roleDesc: '',
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  loading: false,
  alarmType: '',

  // Magna State
  cardCode: '',
  cardName: '',
  cardType: '',
  employeeId: '',
  employeeName: '',
  dept: '',
  userType: '',
}

export default function userReducer(state = initialState, action) {
  console.warn(`Damn State : ${state} and action : ${action}`)
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_PLANT_CODE: {
      return update(state, {
        plantcode: { $set: action.payload.plantcode },
        plantType: { $set: action.payload.planttypecode },
      })
    }
    case actions.SET_DEVICE_CODE_STATE: {
      return update(state, {
        // plantName: { $set: action.payload.plantcode },
        alarmType: { $set: action.payload.alarmType },
      })
    }
    default:
      return state
  }
}
