import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import store from 'store'
// import * as firebase from 'services/firebase'
import * as jwt from 'services/jwt'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* LOGIN({ payload }) {
  const { email, password, userType } = payload
  console.log(`userType :${userType}`)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, email, password, userType)
  console.log(`Login Saga: ${success}`)
  if (success) {
    if (success.jwttoken) {
      // yield put({
      //  type: 'user/LOAD_CURRENT_ACCOUNT',
      // })
      const loginResponse = JSON.parse(success.loginResponse)
      console.log(`loginResponse ${loginResponse}`)
      console.log(`loginResponse[0]["userType"] :${loginResponse[0].userType}`)
      let userpayload = {}
      let loggedInName = ''
      if (loginResponse[0].userType === '1') {
        console.log(`-->${loginResponse[0].cardCode}`)
        console.log(`-->${loginResponse[0].webBlock}`)
        loggedInName = loginResponse[0].cardName
        userpayload = {
          id: loginResponse[0].cardCode,
          name: loginResponse[0].cardName || 'Admin',
          userType: loginResponse[0].userType,
          plantType: '',
          email: '',
          role: success.masterUserInfo[0].userRoleCode,
          roleDesc: 'Supplier',
          groupCode: success.masterUserInfo[0].masterUserGroupCode,

          cardCode: loginResponse[0].cardCode,
          cardName: loginResponse[0].cardName,
          cardType: loginResponse[0].cardType,
          employeeId: loginResponse[0].employeeId,
          employeeName: loginResponse[0].employeeName,
          dept: loginResponse[0].department,

          authorized: true,
        }
      } else if (loginResponse[0].userType === '2') {
        console.log(`-->${loginResponse[0]}`)
        loggedInName = loginResponse[0].employeeName
        userpayload = {
          id: loginResponse[0].employeeId,
          name: loginResponse[0].employeeName || 'Admin',
          userType: loginResponse[0].userType,
          plantType: '',
          email: '',
          role: success.masterUserInfo[0].userRoleCode,
          roleDesc: 'Department',
          groupCode: success.masterUserInfo[0].userRoleCode,

          cardCode: '',
          cardName: '',
          cardType: '',
          employeeId: loginResponse[0].employeeId,
          employeeName: loginResponse[0].employeeName,
          dept: loginResponse[0].department,

          authorized: true,
        }
      }
      yield put({
        type: 'user/SET_STATE',
        payload: userpayload,
      })
      const menuData = JSON.parse(success.menuOptions)
      console.log(`Menu :${menuData}`)
      yield store.set(`app.menu`, menuData)
      yield put({
        type: 'menu/SET_STATE',
        payload: {
          menuData,
        },
      })
      yield history.push('/')
      notification.success({
        message: 'Logged In',
        description: `Welcome Back ! ${loggedInName || 'Admin'}`,
      })
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
      notification.success({
        message: 'Logged Failed',
        description: success.returnMsg,
      })
    }
  }
  if (!success) {
    notification.success({
      message: 'Login Failed',
      description: 'Something went wrong! Check your internet connection.',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, name } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, email, password, name)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  console.log(`inside auth sate`)
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    // const { id, email, name, avatar, role } = response
    const loginResponse = JSON.parse(response.loginResponse)
    console.log(`loginResponse ${loginResponse}`)
    console.log(`loginResponse[0]["userType"] :${loginResponse[0].userType}`)
    let userpayload = {}
    // let loggedInName = ''
    if (loginResponse[0].userType === '1') {
      console.log(`-->${loginResponse[0].cardCode}`)
      console.log(`-->${loginResponse[0].webBlock}`)
      // loggedInName = loginResponse[0].cardName
      userpayload = {
        id: loginResponse[0].cardCode,
        name: loginResponse[0].cardName || 'Admin',
        userType: loginResponse[0].userType,
        plantType: '',
        email: '',
        role: response.masterUserInfo[0].userRoleCode,
        roleDesc: 'Supplier',
        groupCode: response.masterUserInfo[0].masterUserGroupCode,

        cardCode: loginResponse[0].cardCode,
        cardName: loginResponse[0].cardName,
        cardType: loginResponse[0].cardType,
        employeeId: loginResponse[0].employeeId,
        employeeName: loginResponse[0].employeeName,
        dept: loginResponse[0].department,

        authorized: true,
      }
    } else if (loginResponse[0].userType === '2') {
      console.log(`-->${loginResponse[0]}`)
      // loggedInName = loginResponse[0].employeeName
      userpayload = {
        id: loginResponse[0].employeeId,
        name: loginResponse[0].employeeName || 'Admin',
        userType: loginResponse[0].userType,
        plantType: '',
        email: '',
        role: response.masterUserInfo[0].userRoleCode,
        roleDesc: 'Department',
        groupCode: response.masterUserInfo[0].userRoleCode,

        cardCode: '',
        cardName: '',
        cardType: '',
        employeeId: loginResponse[0].employeeId,
        employeeName: loginResponse[0].employeeName,
        dept: loginResponse[0].department,

        authorized: true,
      }
    }

    yield put({
      type: 'user/SET_STATE',
      payload: userpayload,
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
      cardCode: '',
      cardName: '',
      cardType: '',
      employeeId: '',
      employeeName: '',
      dept: '',
    },
  })
}
export function* SET_PLANTCODE(arg) {
  yield put({
    type: 'user/SET_PLANTCODE',
    payload: arg.payload,
  })
  console.log('redirect!')
  history.push('/dashboard/plantdashboard')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.SET_PLANT_CODE_STATE, SET_PLANTCODE),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
