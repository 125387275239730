import apiClient from 'services/axios'
import serverApi from 'services/serverApi'
import store from 'store'

export async function login(email, password, userType) {
  if (userType.match(/^[0-9]+$/) != null) {
    console.log('String contains only numbers')
    return serverApi
      .post(
        `authentication`,
        {
          username: email,
          password,
          usertype: userType,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          },
        },
      )
      .then(response => {
        console.log(`Login Response: ${response}`)
        if (response) {
          const { jwttoken } = response.data
          if (response.data.authenticated) {
            const { userId } = response.data.masterUserInfo[0]
            const { isRedirect } = response.data.masterUserInfo[0]
            if (jwttoken) {
              store.set('accessToken', jwttoken)
              store.set('accessId', userId)
              store.set('userType', userType)
              store.set('isRedirect', isRedirect)
            }
          }

          return response.data
        }
        return false
      })
      .catch(err => console.log(err))
  }
  return false
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  console.log(store.get('accessToken'))
  return serverApi
    .post(
      `refreshtoken`,
      {
        token: store.get('accessToken') || '',
        userId: store.get('accessId') || '',
        userType: store.get('userType') || '',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
      },
    )
    .then(response => {
      console.log(`Refresh Response: ${response}`)
      if (response) {
        const { jwttoken } = response.data
        if (jwttoken) {
          store.set('accessToken', jwttoken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  return apiClient
    .get('/auth/logout')
    .then(() => {
      store.remove('accessToken')
      return true
    })
    .catch(err => console.log(err))
}
